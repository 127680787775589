/* eslint-disable no-restricted-syntax */
import api from '@cannect/services/api'
import { TResponseStandard } from '@cannect/services/types'
import {
  TAuthInformationParams,
  TAuthInformationResponse,
  TCartParams,
  TCartResponse,
  TCheckJourneyPaymentStatusResponse,
  TConfirmIdentityParams,
  TConfirmIdentityResponse,
  TConfirmPaymentStepResponse,
  TConfirmProofAddressDocumentParams,
  TConfirmProofAddressDocumentResponse,
  TConfirmSummaryStepResponse,
  TFindLastJourneyResponse,
  TInstallmentItem,
  TJourneyPaymentParams,
  TJourneyPaymentResponse,
  TSendAnvisaDocumentParams,
  TSendAnvisaDocumentResponse,
  TSendIdentityDocumentParams,
  TSendIdentityDocumentResponse,
  TSendPrescriptionDocumentParams,
  TSendPrescriptionDocumentResponse,
  TSendProofAddressDocumentParams,
  TSendProofAddressDocumentResponse
} from './types'

export const getInstallmentsByOrderId = async (orderId: number) => {
  return api
    .get<TResponseStandard<TInstallmentItem[], 'installments'>>(`/payments/installments/${orderId}`)
    .then((res) => res.data)
}

export const findLastJourney = async (user_id: number | null) => {
  if (!user_id) return null

  return api.get<TFindLastJourneyResponse>(`/digital-journey/find-last-journey/${user_id}`).then((res) => res.data)
}

export const sendPrescriptionDocument = async (data: TSendPrescriptionDocumentParams) => {
  const formData = new FormData()
  formData.append('file', data?.prescription_file)

  const params = data?.user_id ? { user_id: data.user_id } : {}

  return api
    .post<TSendPrescriptionDocumentResponse>('/digital-journey/send-prescription-document', formData, {
      params,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((res) => res.data)
}

export const sendAuthInformation = async (data: TAuthInformationParams) => {
  return api
    .post<TAuthInformationResponse>(`/digital-journey/auth-information/${data.digital_journey_id}`, data)
    .then((res) => res.data)
}

export const updateCart = async (data: TCartParams) => {
  return api.put<TCartResponse>(`/digital-journey/cart/${data.digital_journey_id}`, data).then((res) => res.data)
}

export const sendIdentityDocument = async (data: TSendIdentityDocumentParams) => {
  const formData = new FormData()
  data?.user_id_files?.forEach((file) => {
    formData.append('files', file.file)
  })
  // if it has two files, even if one has an id, it must be read because it will be merged by backend
  const alreadyUploadedFiles = data?.user_id_files?.filter((file) => file.id)
  let params = {}
  if (alreadyUploadedFiles.length === 1 && data?.user_id_files?.length === 1) {
    params = { document_id: alreadyUploadedFiles[0]?.id }
  }
  return api
    .post<TSendIdentityDocumentResponse>(
      `/digital-journey/send-identity-document/${data.digital_journey_id}`,
      formData,
      {
        params,
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
    .then((res) => res.data)
}

export const confirmIdentity = async (data: TConfirmIdentityParams) => {
  return api
    .post<TConfirmIdentityResponse>(`/digital-journey/confirm-identity-document/${data.digital_journey_id}`, data)
    .then((res) => res.data)
}

export const sendProofAddressDocument = async (data: TSendProofAddressDocumentParams) => {
  const formData = new FormData()
  formData.append('file', data.proof_of_address_files?.[0]?.file)

  // if the file has an id, it means that backend already uploaded and read that file, so it will be ignored.
  const params = data?.proof_of_address_files?.[0]?.id ? { document_id: data.proof_of_address_files?.[0]?.id } : {}

  return api
    .post<TSendProofAddressDocumentResponse>(
      `/digital-journey/send-proof-address-document/${data.digital_journey_id}`,
      formData,
      {
        params,
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
    .then((res) => res.data)
}

export const confirmProofAddressDocument = async (data: TConfirmProofAddressDocumentParams) => {
  return api
    .post<TConfirmProofAddressDocumentResponse>(
      `/digital-journey/confirm-proof-address-document/${data.digital_journey_id}`,
      data
    )
    .then((res) => res.data)
}

export const confirmOrderSummaryStep = async (data: { digital_journey_id: string }) => {
  return api
    .post<TConfirmSummaryStepResponse>(`/digital-journey/order-summary/${data.digital_journey_id}`, data)
    .then((res) => res.data)
}

export const journeyPayment = async (data: TJourneyPaymentParams) => {
  const { order_id, ...rest } = data

  return api
    .put<TJourneyPaymentResponse>(`/order_cart_public/${order_id}}`, {
      ...rest
    })
    .then((res) => res.data)
}

export const checkJourneyPaymentStatus = async (order_id: number) => {
  return api.get<TCheckJourneyPaymentStatusResponse>(`/payments/status/${order_id}`).then((res) => res.data)
}

export const confirmJourneyPaymentStep = async (data: { digital_journey_id: string }) => {
  return api
    .post<TConfirmPaymentStepResponse>(`/digital-journey/order-payment/${data.digital_journey_id}`, data)
    .then((res) => res.data)
}
export const sendAnvisaDocument = async (data: TSendAnvisaDocumentParams) => {
  const formData = new FormData()
  formData.append('file', data?.anvisa_authorization_file?.[0]?.file)

  const params = data?.anvisa_authorization_file?.[0]?.id
    ? { document_id: data.anvisa_authorization_file?.[0]?.id }
    : {}

  return api
    .post<TSendAnvisaDocumentResponse>(`/digital-journey/send-anvisa-document/${data.digital_journey_id}`, formData, {
      params,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((res) => res.data)
}
