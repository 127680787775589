import styled, { css } from 'styled-components'
import { theme } from 'design-cannect'
import { temporaryTheme } from 'styles/theme'
import { media } from '@cannect/helpers/mediaQueryStyle'

const MOBILE_TABLET = '(max-width: 1249px)'

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  aling-items: center;
  justify-content: center;
`

export const ImageBox = styled.div`
  width: 100%;
  max-width: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (${MOBILE_TABLET}) {
    width: 100%;
  }
`

export const Image = styled.img`
  width: 100vh;
  height: 70vh;
  object-fit: contain;
  @media (${MOBILE_TABLET}) {
    width: 100%;
    height: auto;
    max-height: 50vh;
  }`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const FirstAccessButton = styled.button`
    height: 7vh;
    width: 35vh;
    background: #464F31;
    border-radius: 08px;
    text-align: center;
    color: white;
    font-family: 'Nunito' sans-serif;
    font-size: 22px;
    font-weight: 400;
    cursor: pointer;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }

    ${media.mobile`
      width: 80%;
      max-width: 250px;
      font-size: 16px;
      font-weight: 400;
    `}
`
