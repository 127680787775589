import React, { createContext, useContext, useState, useMemo, useEffect, ReactNode } from 'react'

import { TDigitalJourneyFormData, TStepName } from '@cannect/pages/DigitalJourney/types'
import {
  CAN_GO_BACK_STEPS,
  DIGITAL_JOURNEY_ROUTES,
  FORM_DEFAULT_VALUES,
  STEPS_ENUM
} from '@cannect/pages/DigitalJourney/constants'
import { useHistory, useLocation } from 'react-router'
import useSignedUser from '../useSignedUser'

type TDigitalJourneyContext = {
  formData: TDigitalJourneyFormData
  updateFormData: (newData: Partial<TDigitalJourneyFormData>) => void
  resetFormData: () => void
  currentStep: TStepName | undefined
  canGoBack: boolean
}

const DigitalJourneyContext = createContext<TDigitalJourneyContext | undefined>(undefined)

export const DigitalJourneyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const history = useHistory()
  const location = useLocation()

  const isDigitalJourneyFlow = useMemo(() => location.pathname.includes('jornada-digital'), [location.pathname])
  const { signedUser } = useSignedUser()

  const [formData, setFormData] = useState<TDigitalJourneyFormData>(FORM_DEFAULT_VALUES)

  const updateFormData = (newData: Partial<TDigitalJourneyFormData>) => {
    setFormData((prevData) => {
      return { ...prevData, ...newData }
    })
  }

  const resetFormData = () => {
    setFormData({
      ...FORM_DEFAULT_VALUES,
      common_fields: {
        ...FORM_DEFAULT_VALUES.common_fields,
        user_id: signedUser?.id
      }
    })
  }

  const currentStep = useMemo(() => {
    return Object.entries(DIGITAL_JOURNEY_ROUTES).find(([_, path]) => path === location?.pathname)?.[0]
  }, [location?.pathname])

  const canGoBack = useMemo(() => {
    if (!currentStep) return false
    return CAN_GO_BACK_STEPS.includes(currentStep)
  }, [currentStep])

  useEffect(() => {
    if (signedUser?.id && !formData.common_fields?.user_id) {
      setFormData((prevData) => ({
        ...prevData,
        common_fields: {
          ...prevData.common_fields,
          user_id: signedUser?.id
        }
      }))
    }
  }, [signedUser?.id, formData.common_fields?.user_id])

  useEffect(() => {
    if (!isDigitalJourneyFlow) return
    if (currentStep !== STEPS_ENUM.SEND_PRESCRIPTION_DOCUMENT && !formData.common_fields?.digital_journey_id) {
      history.push(DIGITAL_JOURNEY_ROUTES[STEPS_ENUM.SEND_PRESCRIPTION_DOCUMENT])
    }
  }, [currentStep, formData.common_fields?.digital_journey_id, isDigitalJourneyFlow])

  useEffect(() => {
    console.log('formData', formData)
  }, [formData])

  const contextValue = useMemo(
    () => ({
      formData,
      resetFormData,
      updateFormData,
      currentStep,
      canGoBack
    }),
    [formData, resetFormData, currentStep, updateFormData, canGoBack]
  )

  return <DigitalJourneyContext.Provider value={contextValue}>{children}</DigitalJourneyContext.Provider>
}

export const useDigitalJourney = (): TDigitalJourneyContext => {
  const context = useContext(DigitalJourneyContext)
  if (context === undefined) {
    throw new Error('useDigitalJourney must be used within a DigitalJourneyProvider')
  }
  return context
}
