import { Badge, Separator } from '@cannect/new-components/atoms'
import { Product, QuantityCounter } from '@cannect/new-components/molecules'
import { TDigitalJourneyFormData } from '@cannect/pages/DigitalJourney/types'

type TCartProductItem = {
  product: TDigitalJourneyFormData['cart']['products'][0]
  handleRemoveProduct: (productId: number) => void
  handleChangeProductQuantity: (productId: number, quantity: number) => void
  handleChangeProductFlavor: (oldProductId: number, newProductId: number) => void
  loadingProducts: Record<number, boolean>
  isLastItem: boolean
}

export const CartProductItem = ({
  product,
  handleRemoveProduct,
  handleChangeProductQuantity,
  handleChangeProductFlavor,
  loadingProducts,
  isLastItem
}: TCartProductItem) => (
  <div className="flex flex-col items-center">
    <Product.Root>
      <Product.RemoveButton onRemove={() => handleRemoveProduct(product.product_id)} />
      <Product.Header>
        <Product.HeaderImage
          src={product?.media?.filter((value) => value.format === 'IMG')?.[0]?.path}
          alt={product?.name}
          isNationalProduct={product?.supplier?.id === 18}
        />
        <Product.HeaderInfo
          name={product?.name}
          volume={product?.medical_data?.volume}
          unitOfMeasurement={product?.medical_data?.unit_of_measurement}
          brandName={product?.brand?.name}
        />
        <Product.HeaderBadges>
          {product?.class?.name && (
            <Badge variant="neutral" className="text-xxs">
              {product?.class?.name}
            </Badge>
          )}
          {product?.flavor && (
            <Badge className="bg-flavors-default-100 text-xxs text-flavors-default-900">
              {product?.flavor?.name || 'Natural'}
            </Badge>
          )}
        </Product.HeaderBadges>
      </Product.Header>
      <div>
        <Product.Footer>
          <div className="flex items-center gap-4">
            <QuantityCounter
              quantity={product?.product_quantity || 0}
              onChangeQuantity={(newQuantity) => handleChangeProductQuantity(product?.product_id, newQuantity)}
              isLoading={loadingProducts[product.product_id]}
            />
            {product?.flavors && product?.flavors.length > 1 && (
              <Product.FlavorSelect
                flavorsList={product?.flavors || []}
                onChange={(newProductId) => handleChangeProductFlavor(product.product_id, newProductId)}
                value={product?.flavor?.name || 'Natural'}
              />
            )}
          </div>
          <Product.Price currentPrice={product?.real_price} valid_promotion={product?.valid_promotion} />
        </Product.Footer>
      </div>
    </Product.Root>
    {!isLastItem && <Separator className="mt-6" />}
  </div>
)
