/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/alt-text */
import { message, Result } from 'antd'
import { NoToken } from 'assets/svg/result'
import Button from 'new-components/Button'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { verifyTypeById } from 'utils/validators'
import { getOrderB2c, getOrderCodB, handleSaveDocument } from './services/documentServices'
import { TEXTINPUTDATA } from './TextInput'
import { TipModal } from './TipModal'
import { DocumentTypes, PublicDocumentResponseTypes } from './types/documentTypes'
import LoadingContainer from 'components/LoadingContainer'
import { useTokenApi } from 'hooks/useTokenApi'
import * as S from './styles'
import { CardUpload } from '@cannect/new-components/molecules'
import {
  TextDocument,
  DocumentTextBox
} from './styles'
import StatusModal from './StatusModal'
import { RiErrorWarningFill, RiCheckFill } from 'react-icons/ri'

import { toast } from 'react-toastify'

type DocumentsProps = {
  orderIdObject: any;
  returnDocs: (data: any) => void;
};

export function Documents({ orderIdObject, returnDocs }: DocumentsProps) {
  const order_id = orderIdObject
  const validateId = order_id && verifyTypeById(order_id.toString())
  const [documentsList, setDocumentsList] = useState({} as PublicDocumentResponseTypes)
  const [openTipModal, setOpenTipModal] = useState(false)
  const [loadingDocuments, setLoadingDocuments] = useState(false)
  const [statusModal, setStatusModal] = useState({
    error: false,
    isOpen: false
  })

  const [tipData, setTipData] = useState<DocumentTypes | null>(null)

  const requestType = async () => {
    if (validateId) {
      setLoadingDocuments(true)

      if (validateId?.type === 'UUID') {
        const data = await getOrderB2c({ order_uuid: validateId.order_id })
        if ('message' in data) {
          message.error(data.message)
        } else {
          setDocumentsList(data)
          returnDocs(data)
        }
      } else {
        const data = await getOrderCodB({ order_id: validateId.order_id })
        if ('message' in data) {
          message.error(data.message)
        } else {
          setDocumentsList(data)
          returnDocs(data)
        }
      }

      setLoadingDocuments(false)
    }
  }

  const handleUpload = async ({ file, type }: { file: File; type: DocumentTypes }) => {
    const uuid = validateId && validateId?.type === 'UUID' ? documentsList?.order_id : undefined
    console.log(order_id)
    try {
      const data = await handleSaveDocument({ uuid, order_id, file, type })
      if ('id' in data) {
        setDocumentsList(prevState => ({
          ...prevState,
          [type]: [...prevState[type], data]
        }))
        returnDocs((prevState:any) => ({
          ...prevState,
          [type]: [...prevState[type], data]
        }))

        if (openTipModal) {
          setOpenTipModal(false)
        }

        setStatusModal({
            error: false,
            isOpen: true
          })

        return { success: true, data }
      }
      throw new Error('Erro ao processar arquivo, por favor, repita a operação.')
    } catch (error: any) {

      setStatusModal({
        error: true,
        isOpen: true
      })
      
      return { success: false, error }
    }
  }

  const handleShowTip = (type: DocumentTypes) => {
    console.log(documentsList)
    setTipData(type)
    setOpenTipModal(true)
  }

  useEffect(() => {
    if (order_id) {
      requestType()
    }
  }, [order_id])

  if (!order_id) {
    return (
      <Result
        icon={<img src={NoToken} />}
        title="Nenhum token foi identificado, acesse a lista de pedidos e tente novamente."
        extra={
          <Button>
            Voltar para <strong>meus pedidos</strong>
          </Button>
        }
      />
    )
  }

  return (
    <S.Wrapper>
      <S.Heading>
        Envio de <span>documentos</span>
      </S.Heading>

      <DocumentTextBox>
        <TextDocument>
          Olá, os documentos solicitados abaixo são uma exigência da Anvisa para efetuar sua importação.
          Porém, não se preocupe, para concluir a sua compra apenas a receita médica precisará ser enviada antecipadamente,
          os outros documentos poderão ser enviados após a finalização.
        </TextDocument>
      </DocumentTextBox>

      <StatusModal
        onClickButton={() => setStatusModal({
          error: false,
          isOpen: false
        })}
        variant={statusModal.error ? 'error' : 'success'}
        icon={statusModal.error ? <RiErrorWarningFill /> : <RiCheckFill/>}
        textButton={statusModal.error ? "Tentar Enviar Novamente" : "Continuar"}
        isOpen={statusModal.isOpen}
        onClose={() => setStatusModal({
          error: false,
          isOpen: false
        })}
        title={statusModal.error ? "Houve um erro ao processar seu pagamento" : "Documento anexado com sucesso"}
        text={statusModal.error ? "Verifique o arquivo e tente novamente" : ""}
      />
      
      {loadingDocuments ? (
        <LoadingContainer loading />
      ) : (
        <S.ContentUpload>
          <CardUpload
            buttonClasses="h-full w-full md:w-[86px] md:min-w-[86px] rounded-none md:p-0 py-2"
            showDescriptionFilesAccept
            currentFilesList={documentsList?.RECEITA}
            title={TEXTINPUTDATA.RECEITA.title}
            description={TEXTINPUTDATA.RECEITA.description}
            isRequired
            onUploadFile={file => handleUpload({ file, type: 'RECEITA' })}
          />

          <CardUpload
            buttonClasses="h-full w-full md:w-[86px] md:min-w-[86px] rounded-none md:p-0 py-2"
            showDescriptionFilesAccept
            currentFilesList={documentsList?.IDENTIDADE}
            title={TEXTINPUTDATA.IDENTIDADE.title}
            description={TEXTINPUTDATA.IDENTIDADE.description}
            onUploadFile={file => handleUpload({ file, type: 'IDENTIDADE' })}
            customTrigger={documentsList.IDENTIDADE?.length === 0 ? () => handleShowTip('IDENTIDADE') : undefined}
          />

          <CardUpload
            buttonClasses="h-full w-full md:w-[86px] md:min-w-[86px] rounded-none md:p-0 py-2"
            showDescriptionFilesAccept
            currentFilesList={documentsList?.RESIDENCIAL}
            title={TEXTINPUTDATA.RESIDENCIAL.title}
            description={TEXTINPUTDATA.RESIDENCIAL.description}
            onUploadFile={file => handleUpload({ file, type: 'RESIDENCIAL' })}
          />

          <CardUpload
            buttonClasses="h-full w-full md:w-[86px] md:min-w-[86px] rounded-none md:p-0 py-2"
            showDescriptionFilesAccept
            currentFilesList={documentsList?.VINCULO}
            title={TEXTINPUTDATA.VINCULO.title}
            description={TEXTINPUTDATA.VINCULO.description}
            onUploadFile={file => handleUpload({ file, type: 'VINCULO' })}
            customTrigger={documentsList.VINCULO?.length === 0 ? () => handleShowTip('VINCULO') : undefined}
          />

          <CardUpload
            buttonClasses="h-full w-full md:w-[86px] md:min-w-[86px] rounded-none md:p-0 py-2"
            showDescriptionFilesAccept
            currentFilesList={documentsList?.ANVISA}
            title={TEXTINPUTDATA.ANVISA.title}
            description={TEXTINPUTDATA.ANVISA.description}
            onUploadFile={file => handleUpload({ file, type: 'ANVISA' })}
            link='https://cannect-upload-homolog.s3.us-east-2.amazonaws.com/Documentos/Modelos/PASSO-A-PASSO-ANVISA.pdf'
            linkTitle='Solicitação anvisa'
          />
        </S.ContentUpload>
      )}

      {tipData && (
        <TipModal
          openTipModal={openTipModal}
          onCloseTip={() => setOpenTipModal(false)}
          type={tipData}
          onConfirmToUpload={file => handleUpload({ file, type: tipData })}
        />
      )}
    </S.Wrapper>
  )
}
