import { forwardRef, useState } from 'react'

import { TCardUploadProps } from './types'

import { Typography } from 'new-components/atoms/Typography'
import { DocumentListFiles } from '@new-components/molecules'
import { FileButton } from '@cannect/new-components/atoms'
import { TItemError, defaultAcceptFormats } from '@cannect/new-components/atoms/FileButton/types'

export const CardUpload = forwardRef((props: TCardUploadProps, ref) => {
  const {
    title,
    description,
    isRequired,
    showDescriptionFilesAccept,
    currentFilesList = [],
    acceptFormats = defaultAcceptFormats,
    linkTitle,
    link
  } = props
  const [error, setError] = useState<TItemError>()

  return (
    <div className="min-w-full flex-1 bg-transparent md:min-w-[647px]">
      <div className="flex w-full flex-col justify-between overflow-hidden rounded-t-lg bg-gray-50 shadow-xl md:h-[120px] md:flex-row md:rounded-l-lg">
        <div className="flex flex-col gap-4 p-3">
          <Typography.Text type="paragraphTwo" weight="bold" className="text-xs text-gray-500 md:text-sm">
            {title}
            {isRequired && <span className="text-error-700"> *</span>}
          </Typography.Text>
          <Typography.Text type="paragraphTwo" className="text-xs text-gray-500 md:text-sm">
            {description}
            {(link && linkTitle) && 
              <>
              <br/>
              <a  href={link} target="_blank">
                {linkTitle} 
              </a>    
              </>
            }
          </Typography.Text>
        </div>

        <FileButton {...props} onError={setError} />
      </div>
      <DocumentListFiles list={currentFilesList} />
      <div className="mt-2 flex items-center justify-between gap-2">
        <Typography.Text type="captionOne" className="text-error-700">
          {error?.message || ''}
        </Typography.Text>

        {showDescriptionFilesAccept && (
          <Typography.Text type="captionOne" className="flex text-gray-300">
            {acceptFormats
              .map((item) => item.slice(1).toUpperCase())
              .slice(0, acceptFormats.length - 1)
              .join(', ')}{' '}
            e{' '}
            {acceptFormats
              .at(acceptFormats.length - 1)
              ?.slice(1)
              ?.toUpperCase()}{' '}
          </Typography.Text>
        )}
      </div>
    </div>
  )
})