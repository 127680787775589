import { STEPS_ENUM } from '@cannect/pages/DigitalJourney/constants'
import { TGroupedSteps } from './types'
import { TStepName } from '@cannect/pages/DigitalJourney/types'

export const GROUPED_STEPS: TGroupedSteps[] = [
  {
    key: 'prescription',
    title: 'Receita',
    steps: [STEPS_ENUM.SEND_PRESCRIPTION_DOCUMENT, STEPS_ENUM.AUTH_INFORMATIONS]
  },
  {
    key: 'documents',
    title: 'Documentos',
    steps: [
      STEPS_ENUM.SEND_IDENTITY_DOCUMENT,
      STEPS_ENUM.SEND_PROOF_OF_ADDRESS_DOCUMENT,
      STEPS_ENUM.CONFIRM_IDENTITY_INFORMATIONS,
      STEPS_ENUM.CONFIRM_PROOF_OF_ADDRESS_INFORMATIONS
    ]
  },
  {
    key: 'payment',
    title: 'Pagamento',
    steps: [STEPS_ENUM.ORDER_PAYMENT]
  },
  {
    key: 'anvisa',
    title: 'Autorização Anvisa',
    steps: [STEPS_ENUM.GENERATE_AND_SIGN_ANVISA_AUTHORIZATION, STEPS_ENUM.SEND_ANVISA_AUTHORIZATION]
  }
]

export const calculateGroupedProgress = (groupSteps: TStepName[], currentStep: TStepName): number => {
  // Find the index of the group that contains the current step
  const currentGroupIndex = GROUPED_STEPS.findIndex(({ steps }) => steps.includes(currentStep))
  // Find the index of the group we're calculating progress for
  const groupIndex = GROUPED_STEPS.findIndex(({ steps }) => steps === groupSteps)

  // If the group we're calculating is before the current group, it's completed
  if (groupIndex < currentGroupIndex) return 100
  // If the group we're calculating is after the current group, it hasn't started
  if (groupIndex > currentGroupIndex) return 0

  // Find the index of the current step within its group
  const currentStepIndex = groupSteps.indexOf(currentStep)

  // If we're on the last step of the group, return 75% instead of 100%
  // This indicates that the group is nearly complete but not fully finished
  if (currentStepIndex === groupSteps.length - 1) {
    return 75
  }

  // Calculate the progress as a percentage
  // We add 1 to currentStepIndex because array indices start at 0
  return ((currentStepIndex + 1) / groupSteps.length) * 100
}
