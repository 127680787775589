/* eslint-disable no-nested-ternary */
import {
  Button,
  CountDownText,
  Form,
  FormController,
  Separator,
  Skeleton,
  Typography
} from '@cannect/new-components/atoms'
import animation from '@cannect/constants/animation'
import { CopyButton, InputField, SelectField } from '@cannect/new-components/molecules'
import { DigitalJourney, RadioGroupCard } from '@cannect/new-components/organisms'
import { QRCodeCanvas } from 'qrcode.react'
import { useOrderPaymentStep } from './useOrderPaymentStep'
import { paymentOptions } from './constants'
import { AnimatePresence, motion } from 'framer-motion'
import Barcode from 'react-barcode'
import { formatToReal } from '@cannect/utils/number'
import { formatDate } from '@cannect/utils/date'
import { LuClock4 } from 'react-icons/lu'

export const OrderPaymentStepUI = ({
  selectedMethod,
  isLoadingInstallments,
  paymentForm,
  onSubmit,
  isLoading,
  installmentsOptions,
  handlePixPayment,
  paymentInfo,
  orderAmount,
  showPaymentStatusScreen,
  handlePaymentStatusClick,
  paymentStatus,
  paymentMethodContentRef
}: ReturnType<typeof useOrderPaymentStep>) => {
  const submitButtonText = {
    boleto: paymentInfo?.boleto_barcode ? 'Copiar código de barras' : 'Gerar boleto',
    pix: 'Copiar código PIX',
    credit_card: 'Pagar'
  }
  return (
    <div className="flex h-full w-full flex-col">
      <AnimatePresence mode="wait">
        {showPaymentStatusScreen ? (
          <motion.div
            key="payment-status"
            className="h-full w-full"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}>
            <DigitalJourney.PaymentStatus
              status={paymentStatus as 'paid' | 'refused'}
              handleClick={handlePaymentStatusClick}
            />
          </motion.div>
        ) : (
          <motion.div
            key="payment-form"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}>
            <Typography.Heading className="!mb-6 !mt-2 text-center text-primary-700" type="headingSix">
              Como você prefere pagar?
            </Typography.Heading>
            <FormController.Form {...paymentForm}>
              <form
                onSubmit={paymentForm.handleSubmit(onSubmit)}
                className="flex flex-grow flex-col overflow-y-auto pb-[140px]">
                <div className="rounded border bg-white px-6 py-8 text-neutral-800 shadow-sm">
                  <FormController.FormField
                    control={paymentForm.control}
                    name="payment_method"
                    render={({ field }) => (
                      <FormController.FormItem>
                        <FormController.FormControl>
                          <RadioGroupCard
                            isDisabled={isLoading || paymentForm.formState.isSubmitting}
                            options={paymentOptions}
                            onValueChange={field.onChange}
                            value={field.value || undefined}
                          />
                        </FormController.FormControl>
                        <FormController.FormMessage />
                      </FormController.FormItem>
                    )}
                  />
                </div>

                <AnimatePresence mode="wait">
                  {selectedMethod === 'credit_card' && (
                    <motion.div {...animation.fadeInUp} ref={paymentMethodContentRef}>
                      <Typography.Heading className="!mb-6 !mt-12 text-center text-primary-700" type="headingSix">
                        Certo! Preencha os dados do cartão, por favor
                      </Typography.Heading>

                      <div className="rounded border bg-white px-6 py-8 text-neutral-800 shadow-sm">
                        <div className="space-y-4">
                          <InputField
                            variant="filledLight"
                            name="credit_card.card_number"
                            control={paymentForm.control}
                            mask="creditCard"
                            placeholder="0000 0000 0000 0000"
                            label="Nº do cartão"
                          />
                          <div className="flex items-end justify-between gap-8">
                            <InputField
                              variant="filledLight"
                              name="credit_card.card_validate"
                              control={paymentForm.control}
                              placeholder="MM/AAAA"
                              label="Data de validade"
                              mask="MonthYear"
                            />
                            <InputField
                              variant="filledLight"
                              name="credit_card.card_cvv"
                              control={paymentForm.control}
                              placeholder="Ex: 123"
                              label="CVV"
                            />
                          </div>

                          <InputField
                            variant="filledLight"
                            name="credit_card.card_holder_name"
                            control={paymentForm.control}
                            placeholder="Nome impresso no cartão"
                            label="Nome no cartão"
                          />
                          <SelectField
                            menuPosition="fixed"
                            customClassNames={{
                              option: '!text-xs'
                            }}
                            variant="outlined"
                            name="credit_card.installments"
                            control={paymentForm.control}
                            label="Parcelas"
                            options={installmentsOptions}
                            isLoading={isLoadingInstallments}
                          />
                        </div>
                      </div>
                    </motion.div>
                  )}

                  <>
                    {selectedMethod === 'pix' && (
                      <motion.div {...animation.fadeInUp} ref={paymentMethodContentRef}>
                        <Typography.Heading className="!mb-6 !mt-12 text-center text-primary-700" type="headingSix">
                          Copie o código PIX abaixo para fazer o pagamento
                        </Typography.Heading>

                        <div className="rounded border bg-white px-6 py-8 text-neutral-800 shadow-sm">
                          {paymentInfo?.pix_qr_code && !isLoading ? (
                            <div className="flex flex-col gap-6">
                              <div className="space-y-1 self-center">
                                <QRCodeCanvas
                                  size={150}
                                  value={paymentInfo?.pix_qr_code}
                                  bgColor="transparent"
                                  fgColor="#000000"
                                  level="L"
                                  includeMargin={false}
                                />
                                <div className="flex items-center gap-1">
                                  <LuClock4 size={14} />

                                  <Typography.Text type="paragraphTwo" className="text-neutral-500">
                                    Expira em: <CountDownText initialTime={300} onExpire={handlePixPayment} />
                                  </Typography.Text>
                                </div>
                              </div>
                              <div className="flex flex-col gap-4">
                                <Typography.Text type="paragraphTwo" className="flex gap-3">
                                  <span className="flex size-6 min-h-6 min-w-6 items-center justify-center rounded-full bg-neutral-100 font-bold tracking-tighter">
                                    1.
                                  </span>
                                  Abra seu internet banking ou aplicativo de pagamentos
                                </Typography.Text>
                                <Typography.Text type="paragraphTwo" className="flex gap-3">
                                  <span className="flex size-6 min-h-6 min-w-6 items-center justify-center rounded-full bg-neutral-100 font-bold tracking-tighter">
                                    2.
                                  </span>
                                  Acesse pagar via PIX
                                </Typography.Text>
                                <Typography.Text type="paragraphTwo" className="flex gap-3">
                                  <span className="flex size-6 min-h-6 min-w-6 items-center justify-center rounded-full bg-neutral-100 font-bold tracking-tighter">
                                    3.
                                  </span>
                                  Cole o código
                                </Typography.Text>
                              </div>
                              <div>
                                <Form.Input
                                  readOnly
                                  aria-readonly
                                  variant="filledLight"
                                  label="Copie e cole o código abaixo"
                                  value={paymentInfo?.pix_qr_code}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="flex w-full flex-col items-center gap-4">
                              <Skeleton className="size-[150px]" />
                              <Skeleton className="-mt-1 h-6 w-[150px]" />
                              <Skeleton className="h-6 w-full" />
                              <Skeleton className="h-6 w-full" />
                              <Skeleton className="h-6 w-full" />
                            </div>
                          )}
                        </div>
                      </motion.div>
                    )}

                    {selectedMethod === 'boleto' && (
                      <motion.div {...animation.fadeInUp} ref={paymentMethodContentRef} layout>
                        <Typography.Heading className="!mb-6 !mt-12 text-center text-primary-700" type="headingSix">
                          Copie o código de barras para fazer o pagamento
                        </Typography.Heading>

                        <div className="rounded border bg-white px-6 py-8 text-neutral-800 shadow-sm">
                          <div className="flex flex-col items-center gap-6">
                            {paymentInfo?.boleto_expiration_date && (
                              <Typography.Text type="paragraphTwo" className="text-center">
                                Seu boleto ficará para pagamento <br />
                                até{' '}
                                <span className="font-semibold">
                                  {formatDate(paymentInfo?.boleto_expiration_date, 'dd/MM/yyyy')}
                                </span>
                                .
                              </Typography.Text>
                            )}
                            <Barcode
                              // @ts-expect-error - Barcode is with wrong types
                              className="max-w-full"
                              width={(paymentInfo?.boleto_barcode?.length || 0) < 10 ? 2 : Number('.6')}
                              value={paymentInfo?.boleto_barcode || '1234 5678'}
                              displayValue={false}
                            />

                            {paymentInfo?.boleto_url && (
                              <Form.Input
                                readOnly
                                aria-readonly
                                variant="filledLight"
                                label="Código de barras"
                                value={paymentInfo?.boleto_barcode}
                              />
                            )}
                            {paymentInfo?.boleto_url ? (
                              <Button
                                unstyled
                                className="-mt-2 text-xs font-medium text-primary-700"
                                onClick={() => window.open(paymentInfo?.boleto_url, '_blank')}>
                                Visualizar boleto
                              </Button>
                            ) : (
                              <Typography.Text type="paragraphTwo" className="text-center">
                                O prazo para compensação do pagamento é de{' '}
                                <span className="font-semibold">3 dias úteis.</span>
                              </Typography.Text>
                            )}
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </>
                </AnimatePresence>
                <div className="fixed bottom-0 left-0 right-2 overflow-hidden bg-primary-50 px-3 pb-2">
                  <div className="px-4">
                    <Separator className="-ml-8 mb-4 mr-2 w-screen" />
                    <Typography.Text muted type="paragraphTwo">
                      Resumo da sua compra:
                    </Typography.Text>
                    <div className="my-2 flex items-center justify-between">
                      <Typography.Text weight="semibold">Total:</Typography.Text>
                      <Typography.Text weight="semibold">{formatToReal(orderAmount)}</Typography.Text>
                    </div>
                  </div>
                  <CopyButton
                    full
                    type={paymentInfo ? 'button' : 'submit'}
                    textToCopy={paymentInfo?.pix_qr_code || paymentInfo?.boleto_barcode}
                    isLoading={paymentForm.formState.isSubmitting || isLoading}
                    disabled={!paymentForm.formState.isValid || !selectedMethod}
                    size="lg"
                    className="font-medium">
                    {submitButtonText[selectedMethod || 'credit_card']}
                  </CopyButton>
                </div>
              </form>
            </FormController.Form>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
