import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import * as requests from './requests'
import { TJourneyPaymentResponse } from './types'

export const KEY_GET_INSTALLMENTS_BY_ORDER_ID = 'getInstallmentsByOrderId'
export const KEY_FIND_LAST_JOURNEY = 'findLastJourney'
export const KEY_JOURNEY_UPDATE_CART_MUTATION = 'updateCart'
export const KEY_GET_JOURNEY_PAYMENT_STATUS = 'getJourneyPaymentStatus'

export const useGetInstallmentsByOrderId = (orderId: number | null, orderAmount?: number) => {
  return useQuery({
    queryKey: [KEY_GET_INSTALLMENTS_BY_ORDER_ID, orderId, orderAmount],
    queryFn: () => requests.getInstallmentsByOrderId(orderId!),
    enabled: !!orderId,
    meta: {
      errorMessage: 'Erro ao buscar as parcelas disponíveis.'
    }
  })
}

export const useFindLastJourney = (user_id?: number | null) => {
  return useQuery({
    queryKey: [KEY_FIND_LAST_JOURNEY, user_id],
    queryFn: () => requests.findLastJourney(user_id!),
    select: (data) => {
      if (data && data.form_data) {
        return {
          ...data,
          form_data: {
            ...data.form_data,
            common_fields: {
              ...data.form_data.common_fields,
              user_id: data.user_id ?? null,
              digital_journey_id: data.digital_journey_id ?? null,
              order_id: data.order_id ?? null
            }
          }
        }
      }
      return data
    },
    enabled: !!user_id
  })
}

export const useSendPrescriptionDocumentMutation = () => {
  return useMutation({
    mutationFn: requests.sendPrescriptionDocument,
    onError: () => {
      toast.error('Erro ao processar receita médica.')
    }
  })
}

export const useAuthInformationMutation = () => {
  return useMutation({
    mutationFn: requests.sendAuthInformation,
    onError: () => {
      toast.error('Erro ao enviar as informações.')
    }
  })
}

export const useUpdateCartMutation = () => {
  return useMutation({
    mutationKey: [KEY_JOURNEY_UPDATE_CART_MUTATION],
    mutationFn: requests.updateCart,
    onError: () => {
      toast.error('Erro ao atualizar o carrinho.')
    }
  })
}

export const useSendIdentityDocumentMutation = () => {
  return useMutation({
    mutationFn: requests.sendIdentityDocument,
    onError: () => {
      toast.error('Erro ao enviar o documento.')
    }
  })
}

export const useConfirmIdentityMutation = () => {
  return useMutation({
    mutationFn: requests.confirmIdentity,
    onError: () => {
      toast.error('Erro ao enviar o documento.')
    }
  })
}

export const useSendProofAddressDocumentMutation = () => {
  return useMutation({
    mutationFn: requests.sendProofAddressDocument,
    onError: () => {
      toast.error('Erro ao enviar o documento.')
    }
  })
}

export const useJourneyPaymentMutation = () => {
  return useMutation({
    mutationFn: requests.journeyPayment,
    onError: (error) => {
      toast.error(error?.response?.data?.error || 'Ocorreu um erro ao processar o pagamento.')
    }
  })
}

export const useGetJourneyPaymentStatusQuery = (
  order_id: number | null,
  paymentInfo: TJourneyPaymentResponse['paymentInfos'] | null
) => {
  return useQuery({
    queryKey: [KEY_GET_JOURNEY_PAYMENT_STATUS, order_id],
    queryFn: () => requests.checkJourneyPaymentStatus(order_id!),
    enabled: !!order_id && !!paymentInfo,
    refetchInterval: (query) => {
      const status = query?.state?.data?.payment?.status
      if (status === 'paid') return false
      if (paymentInfo?.payment_method === 'credit_card' && status === 'refused') return false
      // @TODO voltar aqui para ajustar (quando back corrigir refused retornando ao invés de awaiting_payment)
      return 5000
    }
  })
}

export const useConfirmJourneyPaymentStepMutation = () => {
  return useMutation({
    mutationFn: requests.confirmJourneyPaymentStep
  })
}

export const useConfirmOrderSummaryStepMutation = () => {
  return useMutation({
    mutationFn: requests.confirmOrderSummaryStep,
    onError: () => {
      toast.error('Ocorreu um erro ao confirmar o pagamento.')
    }
  })
}
export const useConfirmProofAddressDocumentMutation = () => {
  return useMutation({
    mutationFn: requests.confirmProofAddressDocument,
    onError: () => {
      toast.error('Erro ao enviar o documento.')
    }
  })
}

export const useSendAnvisaDocumentMutation = () => {
  return useMutation({
    mutationFn: requests.sendAnvisaDocument,
    onError: () => {
      toast.error('Erro ao enviar o documento.')
    }
  })
}
