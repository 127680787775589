import { Button, FormController, Typography } from '@cannect/new-components/atoms'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FileField } from '@cannect/new-components/molecules/FileField'
import { useHistory } from 'react-router'
import { TDigitalJourneyFormData } from '../types'
import { FORM_SCHEMAS } from '../constants'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { useSendIdentityDocumentMutation } from '@cannect/services/resources/digitalJourney'

type TFormFields = TDigitalJourneyFormData['send_identity_document']

export const SendIdentificationStep = () => {
  const history = useHistory()
  const { formData, updateFormData } = useDigitalJourney()
  const sendIdentityDocumentMutation = useSendIdentityDocumentMutation()

  const sendIdentityForm = useForm<TFormFields>({
    defaultValues: {
      ...formData?.send_identity_document
    },
    resolver: zodResolver(FORM_SCHEMAS.send_identity_document)
  })
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid }
  } = sendIdentityForm

  const onSubmit = (data: TFormFields) => {
    if (!formData.common_fields?.digital_journey_id) return null

    return sendIdentityDocumentMutation.mutateAsync(
      {
        digital_journey_id: formData.common_fields?.digital_journey_id,
        ...data
      },
      {
        onSuccess: (response) => {
          updateFormData({
            send_identity_document: {
              user_id_files: [...(response?.form_data?.send_identity_document?.user_id_files || [])],
              next_step: response?.next_step,
              previous_step: response?.previous_step
            },
            confirm_identity_document: {
              cpf: response?.ocr?.send_identity_document?.cpf || '',
              birthday: response?.ocr?.send_identity_document?.birthday || '',
              rg: response?.ocr?.send_identity_document?.rg || '',
              full_name: response?.ocr?.send_identity_document?.full_name || ''
            }
          })
          if (response?.next_step) {
            history.push(response.next_step)
          }
        },
        onError: () => {
          console.log('error')
        }
      }
    )
  }

  return (
    <div className="flex flex-col">
      <div className="mb-8 flex flex-col items-center gap-2">
        <Typography.Heading className="text-center text-primary-700" type="headingSix">
          Você é o titular desta receita?
        </Typography.Heading>
        <Typography.Text type="paragraphTwo" className="text-center">
          Envie um documento de identidade (RG, CNH ou RNE, em caso de estrangeiro) em nome do titular da receita médica
          inserida
        </Typography.Text>
      </div>

      <FormController.Form {...sendIdentityForm}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <FileField
            isDisabled={isSubmitting}
            name="user_id_files"
            control={control}
            showDescriptionFilesAccept
            maxFiles={10}
            canDownload
            showListFiles
            acceptFormats={['.pdf', '.png', '.jpeg', '.jpg']}
          />
          <Button
            className="mt-2 font-medium"
            full
            type="submit"
            isLoading={isSubmitting}
            size="lg"
            disabled={!isValid}>
            Continuar
          </Button>
        </form>
      </FormController.Form>
    </div>
  )
}
