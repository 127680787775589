/* eslint-disable react/jsx-no-useless-fragment */
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import useMediaQuery from '@cannect/hooks/useMediaQuery'
import { Badge, Button, Icons, ScrollArea, Separator, Sheet, Typography } from '@cannect/new-components/atoms'
import { CounterBadge, Product } from '@cannect/new-components/molecules'
import { DIGITAL_JOURNEY_ROUTES, STEPS_ENUM } from '@cannect/pages/DigitalJourney/constants'
import { KEY_JOURNEY_UPDATE_CART_MUTATION } from '@cannect/services/resources/digitalJourney'
import { formatToReal } from '@cannect/utils/number'
import { useMutationState } from '@tanstack/react-query'
import { useMemo } from 'react'
import { LuShoppingCart } from 'react-icons/lu'
import { useHistory } from 'react-router'

export const SheetJourneySummary = () => {
  const history = useHistory()
  const mutationState = useMutationState({
    filters: {
      mutationKey: [KEY_JOURNEY_UPDATE_CART_MUTATION],
      exact: true
    }
  })
  const isLoading = useMemo(() => mutationState[mutationState.length - 1]?.status === 'pending', [mutationState])

  const { formData, currentStep } = useDigitalJourney()

  const isDesktop = useMediaQuery(' (min-width: 768px)')

  const { productsList, summaryFields, journeyStatus } = useMemo(() => {
    return {
      productsList: formData?.cart?.products || [],
      summaryFields: formData?.cart?.summary,
      journeyStatus: formData?.common_fields?.status
    }
  }, [formData?.cart])

  const productsQuantity = useMemo(() => {
    return productsList?.reduce((acc, curr) => acc + curr.product_quantity, 0) || 0
  }, [productsList])

  return (
    <Sheet.Root>
      <Sheet.Trigger asChild>
        <Button unstyled className="header-icon-hover-effect relative">
          <Icons.ShoppingBag className="text-lg" />
          <CounterBadge quantity={productsQuantity} isLoading={isLoading} />
          <span className="sr-only">Carrinho</span> {/* sr-only */}
        </Button>
      </Sheet.Trigger>
      <Sheet.Content className="flex flex-col" side={isDesktop ? 'right' : 'bottom'}>
        <Sheet.Header>
          <Sheet.Title>Resumo do seu pedido</Sheet.Title>
        </Sheet.Header>
        <Separator className="-ml-6 w-screen" />
        <ScrollArea className="h-[90dvh] pr-3">
          {productsList?.length > 0 ? (
            <>
              {productsList?.map((product, index) => {
                const isNationalProduct = product?.supplier?.id === 18
                return (
                  <Product.Root key={product.product_id} className="gap-4">
                    <Product.Header className="gap-x-6">
                      <Product.HeaderInfo
                        name={product?.name}
                        volume={product?.medical_data?.volume}
                        unitOfMeasurement={product?.medical_data?.unit_of_measurement}
                        brandName={product?.brand?.name}
                        className="[&>#product-name]:text-xs"
                      />
                      <Product.HeaderImage
                        className="size-16 justify-self-end border-solid border-neutral-200 shadow-none"
                        src={product?.media?.filter((value) => value.format === 'IMG')?.[0]?.path}
                        alt={product?.name}
                        isNationalProduct={isNationalProduct}
                      />
                      <Product.HeaderBadges>
                        {product?.class?.name && (
                          <Badge variant="neutral" className="text-xxs">
                            {product.class.name}
                          </Badge>
                        )}
                        {product?.flavor && (
                          <Badge className="bg-flavors-default-100 text-xxs text-flavors-default-900">
                            {product?.flavor?.name}
                          </Badge>
                        )}
                      </Product.HeaderBadges>
                    </Product.Header>
                    <div>
                      <Product.Footer className="-mt-1">
                        <Typography.Text type="paragraphTwo">
                          Qtde:{' '}
                          <span className="font-semibold">
                            {product.product_quantity} {product.product_quantity > 1 ? 'itens' : 'item'}
                          </span>
                        </Typography.Text>
                        <Product.Price
                          currentPrice={product?.real_price}
                          valid_promotion={product?.valid_promotion}
                          className="[&>#product-price]:text-base"
                        />
                      </Product.Footer>
                    </div>
                    {index !== productsList.length - 1 && <Separator className="mb-4" />}
                  </Product.Root>
                )
              })}
            </>
          ) : (
            <div className="flex h-full flex-col items-center justify-center">
              <div className="rounded-full bg-primary-100 p-6">
                <LuShoppingCart className="h-12 w-12 text-primary-700" />
              </div>
              <div className="my-4 space-y-2 text-center">
                <Typography.Heading type="headingSix" className="text-2xl font-bold">
                  Seu carrinho está vazio
                </Typography.Heading>
                <Typography.Text type="paragraphTwo" className="text-[#6C757D]">
                  Para continuar é necessário adicionar pelo menos um produto.
                </Typography.Text>
              </div>
            </div>
          )}
        </ScrollArea>
        <Separator className="my-0 -ml-6 w-screen" />

        <Sheet.Footer className="gap-4">
          <div className="flex items-center justify-between">
            <Typography.Text weight="semibold">
              Subtotal{' '}
              <span className="text-xs text-neutral-300">
                ({productsQuantity} {productsQuantity > 1 ? 'itens' : 'item'})
              </span>
            </Typography.Text>
            <Typography.Text weight="semibold">{formatToReal(summaryFields?.amount_total)}</Typography.Text>
          </div>
          <Sheet.Close asChild>
            <Button full type="submit" isLoading={false} size="lg" unstyled className="font-medium text-primary-700">
              Fechar
            </Button>
          </Sheet.Close>
          {currentStep !== STEPS_ENUM.CART && journeyStatus !== 'paid' && (
            <Sheet.Close asChild>
              <Button
                full
                type="submit"
                isLoading={false}
                size="lg"
                className="font-medium"
                onClick={() =>
                  history.push({
                    pathname: DIGITAL_JOURNEY_ROUTES.cart,
                    state: { from: DIGITAL_JOURNEY_ROUTES.order_summary }
                  })
                }>
                Ir para o carrinho
              </Button>
            </Sheet.Close>
          )}
        </Sheet.Footer>
      </Sheet.Content>
    </Sheet.Root>
  )
}
