import api from 'services/api'
import {
  documentByTypeData,
  DocumentResponseTypes,
  DocumentTypes,
  FormattedDocumentsTypes,
  PublicDocumentResponseTypes,
  RecipesResponseTypes,
  ResponseOrderDocumentTypes
} from '../types/documentTypes'
import validateDocs from 'services/ms-documents'

interface responseDocument {
  documents: DocumentResponseTypes[]
  recipies: RecipesResponseTypes[]
  recipes?: RecipesResponseTypes[]
  success?: boolean
  order?: ResponseOrderDocumentTypes
}

const documentTypeArray: DocumentTypes[] = [
  'RESIDENCIAL',
  'RECEITA',
  'IDENTIDADE',
  'ANVISA',
  'IDENTIDADE_RESPONSAVEL',
  'PROCURACAO',
  'VINCULO'
]

type ErrorMessage = {
  message: string
}

type ResponseWithErrorMessage<T> = T | ErrorMessage

export const handleSaveDocument = async ({
  type,
  order_id,
  uuid,
  file
}: {
  type: DocumentTypes
  order_id: string | number
  file: File
  uuid?: string | number
}): Promise<ResponseWithErrorMessage<FormattedDocumentsTypes>> => {
  const DOCUMENT_ROUTES = uuid ? 'order_public/document' : 'order/document'
  const url =
    type === 'RECEITA'
      ? `/order/send_recipe/${order_id}`
      : `${DOCUMENT_ROUTES}/${uuid || order_id}?type=${type}&emission=18%2F11%2F2022`
  try {
    const formData = new FormData()
    formData.append('file', file)
    const { data } = await api.post(url, formData)
    const responseData = type === 'RECEITA' ? data?.recipeSalved : data?.document
    const linkUrl = type === 'RECEITA' ? responseData.link_pdf : responseData?.link_pdf

    if (responseData) {
      return {
        ...responseData,
        status: 'new_file',
        type,
        url: linkUrl || responseData?.url
      }
    }
    throw new Error()
  } catch (e) {
    return { message: 'Não foi possivel enviar o arquivo, tente novamente.' }
  }
}

export const getOrderCodB = async ({
  order_id
}: {
  order_id: number
}): Promise<ResponseWithErrorMessage<documentByTypeData>> => {
  try {
    const { data } = await api.get<responseDocument>(`/order/${order_id}/documents`)

    if (data.success) {
      const documents = data?.order?.documents?.flat() || data?.documents?.flat()
      const recipes = data?.order?.recipies || data?.order?.recipes || data?.recipies || data?.recipes
      const formattedRecipes = (recipes || []).map(recipe => ({
        id: recipe.id,
        type: 'RECEITA',
        url: recipe.url,
        name: recipe.filename,
        status: recipe.status,
        extension: recipe.extension
      }))

      const formattedDocument = (documents || []).map(document => ({
        id: document.id,
        type: document.type,
        url: document.url,
        name: document.originalName,
        status: document.status,
        extension: document.extension
      }))

      const returnData = [...formattedRecipes, ...formattedDocument]
      const transformedObject = documentTypeArray.reduce((acc, key) => {
        acc[key] = returnData.filter(item => item.type === key && item.status !== 'denied')

        return acc
      }, {} as Record<DocumentTypes, any>)

      return transformedObject
    }

    throw new Error()
  } catch (e) {
    return { message: 'Não foi possivel buscar lista de docuemntos. ' }
  }
}

export const getOrderB2c = async ({
  order_uuid
}: {
  order_uuid: string
}): Promise<ResponseWithErrorMessage<PublicDocumentResponseTypes>> => {
  try {
    const { data } = await api.get<responseDocument>(`/order_b2c_public/${order_uuid}`)

    if (data.success) {
      const documents = data?.order?.documents || data?.documents
      const recipes = data?.order?.recipies || data?.order?.recipes || data?.recipies || data?.recipes
      const formattedRecipes = (recipes || []).map(recipe => ({
        id: recipe.id,
        type: 'RECEITA',
        url: recipe.url || recipe.link_pdf,
        name: recipe.filename,
        status: recipe.status,
        extension: recipe.extension
      }))

      const formattedDocument = (documents || []).map(document => ({
        id: document.id,
        type: document.type,
        url: document.url,
        name: document.originalName,
        status: document.status,
        extension: document.extension
      }))

      const returnData = [...formattedRecipes, ...formattedDocument]
      const transformedObject = documentTypeArray.reduce((acc, key) => {
        acc[key] = returnData.filter(item => item.type === key && item.status !== 'denied')

        return acc
      }, {} as Record<DocumentTypes, any>)

      return {
        ...transformedObject,
        order_id: data?.order?.id
      }
    }

    throw new Error()
  } catch (e) {
    return { message: 'Não foi possivel buscar lista de docuemntos. ' }
  }
}
