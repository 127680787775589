import { Button, FormController, Typography } from '@cannect/new-components/atoms'
import { InputField } from '@cannect/new-components/molecules'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useHistory } from 'react-router'
import { DIGITAL_JOURNEY_ROUTES, FORM_SCHEMAS } from '../constants'

import { TDigitalJourneyFormData } from '../types'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { useConfirmProofAddressDocumentMutation } from '@cannect/services/resources/digitalJourney'

type TFormFields = TDigitalJourneyFormData['confirm_proof_of_address_document']

export const ConfirmProofOfAddressStep = () => {
  const history = useHistory()
  const { formData, updateFormData } = useDigitalJourney()

  const confirmIdentificationForm = useForm<TFormFields>({
    defaultValues: {
      ...formData?.confirm_proof_of_address_document
    },
    resolver: zodResolver(FORM_SCHEMAS.confirm_proof_of_address_document)
  })

  const confirmProofOfAddressDocumentMutation = useConfirmProofAddressDocumentMutation()
  const {
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = confirmIdentificationForm

  const onSubmit = (data: TFormFields) => {
    if (!formData.common_fields?.digital_journey_id) return null

    return confirmProofOfAddressDocumentMutation.mutateAsync(
      {
        digital_journey_id: formData.common_fields?.digital_journey_id,
        ...data
      },
      {
        onSuccess: (response) => {
          updateFormData({
            confirm_proof_of_address_document: {
              ...data
            }
          })
          if (response?.next_step) {
            history.push(DIGITAL_JOURNEY_ROUTES.order_summary)
          }
        },
        onError: () => {
          console.log('error')
        }
      }
    )
  }

  return (
    <div className="flex flex-col">
      <div className="mb-4 flex flex-col items-center gap-3">
        <Typography.Heading className="text-center text-primary-700" type="headingSix">
          Os dados estão certos?
        </Typography.Heading>
        <Typography.Text type="paragraphTwo" className="text-center">
          Você pode corrigir qualquer equívoco nos campos abaixo.
        </Typography.Text>
      </div>
      <FormController.Form {...confirmIdentificationForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 rounded bg-white p-4 shadow-md">
            <Typography.Text type="paragraphTwo" className="!mb-3 text-primary-900" weight="medium">
              Insira os dados nos campos abaixo:
            </Typography.Text>
            <InputField
              variant="filledLight"
              name="address_holder_full_name"
              label="Nome do titular"
              control={confirmIdentificationForm.control}
            />
            <InputField
              variant="filledLight"
              name="address_holder_cpf"
              label="CPF do titular"
              control={confirmIdentificationForm.control}
              mask="cpf"
            />
            <InputField
              variant="filledLight"
              name="address_cep"
              label="CEP"
              control={confirmIdentificationForm.control}
              mask="cep"
            />
            <InputField
              variant="filledLight"
              name="address_state"
              label="Estado"
              control={confirmIdentificationForm.control}
            />
            <InputField
              variant="filledLight"
              name="address_city"
              label="Cidade"
              control={confirmIdentificationForm.control}
            />

            <InputField
              variant="filledLight"
              name="address_neighborhood"
              label="Bairro"
              control={confirmIdentificationForm.control}
            />
            <InputField
              variant="filledLight"
              name="address_street"
              label="Rua"
              control={confirmIdentificationForm.control}
            />
            <InputField
              variant="filledLight"
              name="address_number"
              label="Número"
              control={confirmIdentificationForm.control}
              mask="onlyNumbers"
            />
            <InputField
              variant="filledLight"
              name="address_complement"
              label="Complemento"
              control={confirmIdentificationForm.control}
            />
          </div>
          <Button
            className="mt-8 font-medium"
            full
            type="submit"
            isLoading={isSubmitting}
            size="lg"
            disabled={!isValid}>
            Confirmar
          </Button>
        </form>
      </FormController.Form>
    </div>
  )
}
