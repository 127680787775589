import { VideoMp4 } from './icons/VideoMp4'
import { Csv } from './icons/Csv'
import { Doc } from './icons/Doc'
import { Png } from './icons/Png'
import { Pdf } from './icons/PDF'
import { Xlsx } from './icons/Xlxs'
import { TextIcon } from './icons/Text'
import { ElementType, useMemo, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { LuDownload, LuFileArchive, LuTrash2 } from 'react-icons/lu'
import { JPG } from './icons/JPG'
import { Zip } from './icons/Zip'
import { formatDate } from '@cannect/utils/date'
import { RxDotsVertical } from 'react-icons/rx'
import { ImSpinner2 } from 'react-icons/im'
import { TModalConfirmMethods } from '@cannect/new-components/molecules/ModalConfirm/types'
import { Dropdown, Typography } from '@cannect/new-components/atoms'
import { ModalConfirm } from '../ModalConfirm'
import { acceptDefaults, TFileItemProps, TFormats } from './types'
import { ModalPreviewFile } from '../ModalPreviewFile'
import { TModalPreviewFileMethods } from '../ModalPreviewFile/types'
import { toast } from 'react-toastify'
import { formatFileSize } from './utils'

export const IconList: Record<TFormats, ElementType> = {
  '.csv': Csv,
  '.doc': Doc,
  '.pdf': Pdf,
  '.png': Png,
  '.mp4': VideoMp4,
  '.xlsx': Xlsx,
  '.docx': Doc,
  '.jpeg': JPG,
  '.jpg': JPG,
  '.txt': TextIcon,
  '.zip': Zip
}

export function FileItem({
  fileItem,
  download = true,
  view = true,
  remove = false,
  dashed = false,
  animate = true,
  onRemove
}: TFileItemProps) {
  const serializedType = useMemo(() => acceptDefaults[fileItem?.type]?.[0], [fileItem?.type]) as TFormats
  const IconFileType = IconList[serializedType]
  const refModalConfirm = useRef<TModalConfirmMethods>(null)
  const refModalViewFile = useRef<TModalPreviewFileMethods>(null)

  const [isDownloading, setIsDownloading] = useState(false)

  const parsedSize = formatFileSize(fileItem?.size)

  const handleDownloadFile = () => {
    setIsDownloading(true)

    if (!fileItem?.file_url) return

    fetch(fileItem?.file_url)
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob)

        const a = document.createElement('a')

        a.href = url

        a.download = fileItem?.name || 'download'

        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url)

            // eslint-disable-next-line no-restricted-globals
            removeEventListener('click', clickHandler)
          }, 150)
        }

        a.addEventListener('click', clickHandler)

        a.click()

        return a
      })
      .catch(() => {
        toast.error('Erro ao baixar o arquivo')
      })
      .finally(() => setIsDownloading(false))
  }

  const allowedViewFileTypes = ['.pdf', '.png', '.jpg', '.jpeg']

  return (
    <motion.div
      {...(animate && { whileHover: { translateY: -5 } })}
      className={`flex items-center rounded-2xl border ${dashed && 'border-dashed'} border-gray-300 px-6 py-4`}>
      <IconFileType />
      <div className="ml-2 flex flex-1 flex-col">
        <Typography.Text
          type="paragraphTwo"
          weight="bold"
          title={fileItem?.name || 'Documento'}
          className="max-w-[15rem] truncate text-gray-700">
          {fileItem?.name || 'Documento'}
        </Typography.Text>
        <Typography.Text type="paragraphTwo" weight="regular" className="text-gray-500">
          {serializedType?.slice(1)?.toUpperCase()} {parsedSize?.split(' ').join('').toUpperCase()}{' '}
          {fileItem?.createdAt && formatDate(fileItem?.createdAt, 'dd/MM/yyyy')}
        </Typography.Text>
      </div>
      <Dropdown.Menu modal={false}>
        <Dropdown.MenuTrigger
          className="flex size-10 items-center justify-center border-none bg-transparent"
          data-testid="file_item_more">
          <RxDotsVertical size={20} className="text-primary-700" />
        </Dropdown.MenuTrigger>
        <Dropdown.MenuContent>
          {view && fileItem?.file_url && allowedViewFileTypes.includes(serializedType) && (
            <Dropdown.MenuItem
              className="justify-between py-3"
              onSelect={() => refModalViewFile.current?.onShow()}
              data-testid="file_item_view">
              Visualizar <LuFileArchive size={18} />
            </Dropdown.MenuItem>
          )}
          {download && (
            <Dropdown.MenuItem
              className="justify-between py-3"
              onSelect={(e) => {
                e.preventDefault()
                handleDownloadFile()
              }}
              data-testid="file_item_download">
              Baixar{' '}
              {isDownloading ? (
                <ImSpinner2 size={18} className="animate-spin text-primary-700" />
              ) : (
                <LuDownload size={18} />
              )}
            </Dropdown.MenuItem>
          )}
          {remove && (
            <Dropdown.MenuItem
              className="justify-between py-3"
              onSelect={() => refModalConfirm.current?.onShow()}
              data-testid="file_item_remove">
              Remover <LuTrash2 size={18} className="text-error-700" />
            </Dropdown.MenuItem>
          )}
        </Dropdown.MenuContent>
      </Dropdown.Menu>
      <ModalConfirm
        ref={refModalConfirm}
        title="Deseja realmente remover esse arquivo?"
        text="Ao confirmar, o arquivo será removido da lista."
        onConfirm={() => {
          onRemove?.()
          refModalConfirm.current?.onClose()
        }}
      />
      {fileItem?.file_url && (
        <ModalPreviewFile ref={refModalViewFile} file_url={fileItem?.file_url} fileType={serializedType} />
      )}
    </motion.div>
  )
}
