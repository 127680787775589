import { Button, FormController, Typography } from '@cannect/new-components/atoms'
import { InputField } from '@cannect/new-components/molecules'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useHistory } from 'react-router'
import { TDigitalJourneyFormData } from '../types'
import { FORM_SCHEMAS } from '../constants'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { useConfirmIdentityMutation } from '@cannect/services/resources/digitalJourney'

type TFormFields = TDigitalJourneyFormData['confirm_identity_document']

export const ConfirmIdentificationStep = () => {
  const { formData, updateFormData } = useDigitalJourney()
  const confirmIdentityMutation = useConfirmIdentityMutation()

  const history = useHistory()
  const confirmIdentificationForm = useForm<TFormFields>({
    defaultValues: {
      ...formData?.confirm_identity_document
    },
    resolver: zodResolver(FORM_SCHEMAS.confirm_identity_document)
  })

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid, isSubmitting }
  } = confirmIdentificationForm

  const onSubmit = (data: TFormFields) => {
    if (!formData.common_fields?.digital_journey_id) return null

    return confirmIdentityMutation.mutateAsync(
      {
        digital_journey_id: formData.common_fields?.digital_journey_id,
        ...data
      },
      {
        onSuccess: (response) => {
          updateFormData({
            confirm_identity_document: {
              ...data
            }
          })
          if (response?.next_step) {
            history.push(response.next_step)
          }
        },
        onError: () => {
          console.log('error')
        }
      }
    )
  }

  console.log('watch', watch())
  console.log('errors', errors)

  return (
    <div className="flex flex-col">
      <div className="mb-4 flex flex-col items-center gap-2">
        <Typography.Heading className="text-center text-primary-700" type="headingSix">
          Os dados inseridos estão certos?
        </Typography.Heading>
        <Typography.Text type="paragraphTwo" className="text-center">
          Você pode corrigir qualquer equívoco nos campos abaixo.
        </Typography.Text>
      </div>

      <FormController.Form {...confirmIdentificationForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 rounded bg-white p-4 shadow-md">
            <Typography.Text type="paragraphTwo" className="text-primary-900" weight="medium">
              Dados pessoais
            </Typography.Text>
            <InputField variant="filledLight" name="full_name" label="Nome completo" control={control} />

            <InputField
              variant="filledLight"
              name="birthday"
              label="Data de nascimento"
              control={control}
              mask="DayMonthYear"
            />
            <InputField variant="filledLight" name="cpf" label="CPF" control={control} mask="cpf" />
            <InputField variant="filledLight" name="rg" label="RG" control={control} mask="rg" />
          </div>
          <Button
            className="mt-8 font-medium"
            full
            type="submit"
            isLoading={isSubmitting}
            size="lg"
            disabled={!isValid}>
            Confirmar
          </Button>
        </form>
      </FormController.Form>
    </div>
  )
}
