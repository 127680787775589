import React, { FunctionComponent } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { DigitalJourney } from '@cannect/new-components/organisms'
import animation from '@cannect/constants/animation'

import { DIGITAL_JOURNEY_ROUTES, STEPS_ENUM } from './constants'
import * as DigitalJourneySteps from './Steps'
import { TStepName } from './types'
import { Route, useHistory } from 'react-router'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { Button } from '@cannect/new-components/atoms'
import { LuChevronLeft } from 'react-icons/lu'

const STEP_COMPONENTS: Record<TStepName, FunctionComponent> = {
  [STEPS_ENUM.SEND_PRESCRIPTION_DOCUMENT]: DigitalJourneySteps.SendPrescription,
  [STEPS_ENUM.AUTH_INFORMATIONS]: DigitalJourneySteps.Auth,
  [STEPS_ENUM.CART]: DigitalJourneySteps.Cart,
  [STEPS_ENUM.SEND_IDENTITY_DOCUMENT]: DigitalJourneySteps.SendIdentification,
  [STEPS_ENUM.CONFIRM_IDENTITY_INFORMATIONS]: DigitalJourneySteps.ConfirmIdentification,
  [STEPS_ENUM.SEND_PROOF_OF_ADDRESS_DOCUMENT]: DigitalJourneySteps.SendProofOfAddress,
  [STEPS_ENUM.CONFIRM_PROOF_OF_ADDRESS_INFORMATIONS]: DigitalJourneySteps.ConfirmProofOfAddress,
  [STEPS_ENUM.ORDER_PAYMENT]: DigitalJourneySteps.OrderPayment,
  [STEPS_ENUM.ORDER_SUMMARY]: DigitalJourneySteps.OrderSummary,
  [STEPS_ENUM.GENERATE_AND_SIGN_ANVISA_AUTHORIZATION]: DigitalJourneySteps.GenerateAndSignAnvisaAuthorization,
  [STEPS_ENUM.SEND_ANVISA_AUTHORIZATION]: DigitalJourneySteps.SendAnvisaAuthorization
}

export const DigitalJourneyPage = () => {
  const { currentStep } = useDigitalJourney()

  if (!currentStep) return null
  return (
    <div className="mx-auto flex h-full min-h-[calc(100dvh-74px)] w-full max-w-screen-sm flex-col gap-4 px-8 py-6 md:min-h-[calc(100dvh-106px)]">
      <div className="absolute inset-0 left-0 top-0 -z-[1] h-[100dvh] w-screen bg-primary-50" />
      <DigitalJourney.Progress currentStep={currentStep} />
      <AnimatePresence initial={false} mode="popLayout">
        <motion.div key={currentStep} layout {...animation.fadeInUp} className="h-full">
          {Object.entries(DIGITAL_JOURNEY_ROUTES).map(([step, path]) => {
            const StepComponent = STEP_COMPONENTS[step]
            return StepComponent ? <Route key={path} path={path} component={StepComponent} exact /> : null
          })}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
