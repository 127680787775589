import { useState } from "react"

export default function ClicksignPage() {
    let [signID, setSignID] = useState('')

    function run() {
        const widget = new Clicksign(signID);

        console.log({ widget })

        widget.endpoint = 'https://sandbox.clicksign.com';
        widget.origin = 'https://dev.cannect.life';
        widget.mount('container');

        widget.on('loaded', function (ev) { console.log('loaded!'); });
        widget.on('signed', function (ev) { console.log('signed!'); });
    }
    return (
        <div>
            <input id='request_signature_id' value={signID} onChange={(event) => setSignID(event.target.value)} />
            <input type='button' value='Load' onClick={run} />
            <div id='container'></div>
        </div >
    )
}