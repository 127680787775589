import { useAuth } from '@cannect/hooks/AuthContext'
import { useCannectAssistant } from '@cannect/hooks/useCannectAssistant'
import { usePrepareCart } from '@cannect/hooks/usePrepareCart'
import useQueryString from '@cannect/hooks/useQueryString'
import useSignedUser from '@cannect/hooks/useSignedUser'
import { useTokenApi } from '@cannect/hooks/useTokenApi'
import { TLinkItem } from '@cannect/new-components/molecules/LinkItem/types'
import { usePublicRender } from '@cannect/utils/renderActions'
import { deleteUser } from 'hooks/useSessionUser'
import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

export const useHeader = () => {
  const history = useHistory()
  const location = useLocation()
  const { filters } = useQueryString()
  const { signedUser } = useSignedUser()
  const { deleteTokenApi, tokenApi } = useTokenApi()
  const { prescription, setStep, loading } = useCannectAssistant()
  const { getRoles } = useAuth()
  const { getLastOrder, lastOrderCreated, loadingCupon } = usePrepareCart()
  const { isPublic: isPublicFlow } = usePublicRender()

  const signedUserName = useMemo(() => {
    const name = signedUser?.preferred_name || signedUser?.name || ''
    return name.split(' ')[0]
  }, [signedUser])

  const isAssistantFlow = useMemo(() => location.pathname.startsWith('/produtos_assistente'), [location])

  const isDigitalJourneyFlow = useMemo(() => location.pathname.includes('jornada-digital'), [location?.pathname])

  const getProductQuantity = (products: any) => {
    return (
      // @ts-expect-error - we need to type products
      products?.reduce((acc, curr) => {
        const itemQuantity = curr.quantity || curr.quantity_item || 0
        return acc + itemQuantity
      }, 0) || 0
    )
  }

  const cartProductsQuantity = useMemo(() => {
    if (isAssistantFlow) {
      return getProductQuantity(prescription?.products)
    }

    return getProductQuantity(lastOrderCreated?.items)
  }, [isAssistantFlow, prescription?.products, lastOrderCreated?.items])

  const isLogged = useMemo(() => !!tokenApi, [tokenApi])

  const allowedAccessToAssistente = ['CRM', 'CRO', 'RMS']

  const hasAssistentAccess = allowedAccessToAssistente.includes(signedUser?.prescriber?.council || '')

  const prescriberHasLogo = useMemo(
    () => prescription?.prescriber?.profilePicture,
    [prescription?.prescriber?.profilePicture]
  )

  const handleLogout = () => {
    deleteTokenApi()
    deleteUser()
    getRoles()
    history.push('/login')
  }

  const handleAssistantContinue = () => {
    const validateStep = prescriberHasLogo ? 2 : 1
    setStep((step) => step + validateStep)
    let url = '/assistente'
    if (filters.template) {
      url += `?template=${filters.template}`
    }
    history.push(url)
  }

  useEffect(() => {
    if (tokenApi) {
      getLastOrder() // get last order when user is logged in
    }
  }, [tokenApi])

  const menuConfig: TLinkItem[] = [
    {
      title: 'Agendamento',
      path: '/agendamento'
    },
    {
      title: 'Produtos',
      path: '/produtos/1'
    },

    {
      title: 'Profissionais da Saúde',
      submenu: [
        { title: 'Conheça as soluções', path: '/area-profissionais' },
        {
          title: 'Assistente de prescrição',
          path: '/assistente',
          hidden: !hasAssistentAccess || !isLogged
        },
        { title: 'Cuidado coordenado', path: '/cuida' },
        { title: 'Materiais de educação', path: '/educa' },
        { title: 'Artigos científicos', path: '/cannect-ciencia' }
      ]
    },
    {
      title: 'Soluções e parcerias',
      submenu: [
        { title: 'Seja nosso parceiro', path: '/area-parceiros' },
        { title: 'Operadoras de saúde', path: 'https://atendimento.cannect.life/operadoras-de-saude', external: true }
      ]
    },
    {
      title: 'Blog',
      path: '/blog'
    }
  ]

  return {
    menuConfig,
    signedUserName,
    handleLogout,
    isLogged,
    cartProductsQuantity,
    isAssistantFlow,
    handleAssistantContinue,
    isLoadingCartQuantity: loadingCupon || loading,
    isPublicFlow,
    isDigitalJourneyFlow
  }
}
