import React, { forwardRef, HTMLAttributes, ImgHTMLAttributes, useMemo, useRef } from 'react'
import { Button, Typography, ProgressBar, Drawer, Separator, Dropdown } from '@cannect/new-components/atoms'
import { ModalConfirm } from '@cannect/new-components/molecules'
import { LuTrash2 } from 'react-icons/lu'
import { cn } from '@cannect/lib/utils'
import { TModalConfirmMethods } from '@cannect/new-components/molecules/ModalConfirm/types'
import { buttonVariants } from '@cannect/new-components/atoms/Button'
import useSignedUser from '@cannect/hooks/useSignedUser'
import { cannectDefaultProduct, cannectDefaultProductNoTarg } from '@cannect/assets/img'
import { THeaderInfo, THeaderImage, TFreeShippingProgress, TPrice, TRemoveButton, TFlavorSelect } from './types'
import { formatToReal } from '@cannect/utils/number'
import useMediaQuery from '@cannect/hooks/useMediaQuery'
import { MdStars } from 'react-icons/md'

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('relative flex w-full flex-col gap-6', className)} {...props} />
))
Root.displayName = 'Product.Root'

const Header = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('grid grid-cols-[auto,1fr] gap-x-2 gap-y-1', className)} {...props} />
))
Header.displayName = 'Product.Header'

const HeaderImage = forwardRef<HTMLImageElement, THeaderImage & ImgHTMLAttributes<HTMLImageElement>>(
  ({ className, isNationalProduct, src, alt = 'product-img', ...props }, ref) => {
    const { signedUser } = useSignedUser()
    const isPatientUser = useMemo(() => signedUser?.registration_type === 1, [signedUser])

    const defaultImage = isNationalProduct ? cannectDefaultProduct : cannectDefaultProductNoTarg
    const imageSource = isPatientUser ? defaultImage : src || defaultImage

    return (
      <img
        src={imageSource}
        alt={alt}
        ref={ref}
        className={cn('row-span-2 h-23 w-22 rounded-md object-cover shadow-md sm:max-w-xs', className)}
        {...props}
      />
    )
  }
)

HeaderImage.displayName = 'Product.HeaderImage'

const HeaderInfo = forwardRef<HTMLDivElement, THeaderInfo & HTMLAttributes<HTMLDivElement>>(
  ({ className, name, volume, unitOfMeasurement, brandName, ...props }, ref) => {
    const hasMedicalInfo = volume && unitOfMeasurement
    return (
      <div ref={ref} className={cn('flex flex-col gap-[2px]', className)} {...props}>
        <Typography.Text
          id="product-name"
          type="paragraphTwo"
          weight="semibold"
          className="line-clamp-2 max-w-[calc(100%-18px)] md:text-lg">
          {name || ''}
        </Typography.Text>
        {hasMedicalInfo && (
          <Typography.Text type="captionOne" className="mb-1" id="product-volume">
            {volume || ''}
            {unitOfMeasurement?.toLocaleLowerCase() || ''}
          </Typography.Text>
        )}
        <Typography.Text type="captionOne" muted weight="semibold" id="product-brand">
          {brandName || ''}
        </Typography.Text>
      </div>
    )
  }
)
HeaderInfo.displayName = 'Product.HeaderInfo'

const HeaderBadges = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('flex flex-wrap gap-2', className)} {...props} />
))

HeaderBadges.displayName = 'Product.HeaderBadges'

const Footer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('mt-4 flex items-center justify-between', className)} {...props} />
))
Footer.displayName = 'Product.Footer'

const Price = forwardRef<HTMLDivElement, TPrice & HTMLAttributes<HTMLDivElement>>(
  ({ className, currentPrice, valid_promotion, ...props }, ref) => {
    if (!currentPrice) return null

    const hasValidPromotion = valid_promotion?.regular_price && valid_promotion?.promotional_price

    return (
      <div ref={ref} className={cn('text-right', className)} {...props}>
        {hasValidPromotion && valid_promotion?.regular_price && (
          <Typography.Text type="captionOne" muted className="line-through" id="product-promotional-price">
            {formatToReal(valid_promotion?.regular_price)}
          </Typography.Text>
        )}

        {!hasValidPromotion && (
          <Typography.Text type="paragraphTwo" weight="semibold" id="product-price">
            {formatToReal(currentPrice)}
          </Typography.Text>
        )}

        {hasValidPromotion && valid_promotion?.promotional_price && (
          <Typography.Text type="paragraphTwo" weight="semibold" id="product-price">
            {formatToReal(valid_promotion.promotional_price)}
          </Typography.Text>
        )}
      </div>
    )
  }
)

Price.displayName = 'Product.Price'

const RemoveButton = forwardRef<HTMLButtonElement, TRemoveButton>(
  ({ className, onRemove, shouldConfirmBeforeRemove = true, ...props }, ref) => {
    const modalRemoveConfirmRef = useRef<TModalConfirmMethods>(null)
    const handleClick = () => {
      if (shouldConfirmBeforeRemove) {
        modalRemoveConfirmRef.current?.onShow()
      } else {
        onRemove()
      }
    }
    return (
      <>
        <Button
          ref={ref}
          unstyled
          className={cn(
            'absolute right-0 top-0 text-lg text-neutral-500 transition-all duration-300 ease-in-out hover:scale-105',
            className
          )}
          onClick={handleClick}
          {...props}>
          <LuTrash2 />
        </Button>
        <ModalConfirm
          ref={modalRemoveConfirmRef}
          title="Deseja realmente remover esse produto?"
          text="Ao confirmar, o produto será removido do carrinho."
          onConfirm={() => {
            onRemove()
            modalRemoveConfirmRef?.current?.onClose()
          }}
        />
      </>
    )
  }
)
RemoveButton.displayName = 'Product.RemoveButton'

const FreeShippingProgress = forwardRef<HTMLDivElement, TFreeShippingProgress & HTMLAttributes<HTMLDivElement>>(
  ({ summary, className, ...props }, ref) => {
    const FREE_SHIPPING_MINIMUM = 2500

    if (!summary) return null

    const remainingForFreeShipping = Math.max(FREE_SHIPPING_MINIMUM - summary.amount_total, 0)
    const progress = Math.min((summary.amount_total / FREE_SHIPPING_MINIMUM) * 100, 100)
    const achievedGoal = remainingForFreeShipping === 0

    return (
      <div ref={ref} {...props} className={cn('w-full space-y-4', className)}>
        <Typography.Text type="paragraphTwo" weight="semibold">
          Custo de importação
        </Typography.Text>

        <ProgressBar
          value={progress}
          className="h-[10px] w-full"
          indicatorClassName={achievedGoal ? 'bg-success-700' : ''}
        />

        {achievedGoal ? (
          <Typography.Text type="paragraphTwo">
            <span className="font-semibold">Parabéns, você ganhou!</span>
            <br />
            Custo de importação <span className="font-semibold text-primary-700">grátis!</span>
          </Typography.Text>
        ) : (
          <Typography.Text type="paragraphTwo">
            Faltam <span className="font-semibold">{formatToReal(Math.max(remainingForFreeShipping, 0))}</span> para{' '}
            <span className="font-semibold">Custo de importação grátis</span>
          </Typography.Text>
        )}
        <Typography.Text type="captionOne" className={achievedGoal ? 'text-neutral-300' : ''}>
          Compre acima de <span className="font-semibold">R$ {FREE_SHIPPING_MINIMUM},00</span> e tenha custo de
          importação grátis em qualquer canal de pagamento disponível.
        </Typography.Text>
      </div>
    )
  }
)

FreeShippingProgress.displayName = 'Product.FreeShippingProgress'

const FlavorSelect = ({
  flavorsList,
  onChange,
  value,
  className,
  triggerText = 'Alterar sabor',
  isDisabled,
  ...triggerVariantsProps
}: TFlavorSelect) => {
  const isDesktop = useMediaQuery(' (min-width: 768px)')

  const { unstyled } = triggerVariantsProps

  const triggerVariants = buttonVariants({
    ...triggerVariantsProps,
    unstyled: unstyled ?? true,
    className: 'text-primary-700 text-xs font-normal'
  })

  const RecommendedText = 'Recomendado'

  const closeRef = useRef<HTMLButtonElement>(null)

  const isOnlyOneFlavor = flavorsList?.length === 1

  const shouldDisableTrigger = isOnlyOneFlavor || isDisabled

  if (isDesktop)
    return (
      <Dropdown.Menu modal={false}>
        <Dropdown.MenuTrigger disabled={shouldDisableTrigger} className={cn(triggerVariants, className)}>
          {triggerText}
        </Dropdown.MenuTrigger>
        <Dropdown.MenuContent>
          {flavorsList
            .sort((a, b) => a.id - b.id)
            .map((flavor) => (
              <Dropdown.MenuItem
                className={`my my-2 flex h-[52px] items-center justify-between border-b ${flavor?.name || (flavor?.flavor === value && 'bg-secondary-100')}`}
                onSelect={() => onChange(flavor.id)}>
                <div>
                  <Typography.Text muted weight="semibold" type="paragraphTwo">
                    {flavor?.name || flavor?.flavor}
                  </Typography.Text>
                  {flavor?.isRecommendedFlavor && (
                    <div className="flex items-center gap-2">
                      <MdStars size={20} className="text-primary-700" />
                      <Typography.Text type="captionOne" muted className="text-primary-700">
                        {RecommendedText}
                      </Typography.Text>
                    </div>
                  )}
                </div>
              </Dropdown.MenuItem>
            ))}
        </Dropdown.MenuContent>
      </Dropdown.Menu>
    )

  return (
    <Drawer.Root>
      <Drawer.Trigger disabled={shouldDisableTrigger} className={cn(triggerVariants, className)}>
        {triggerText}
      </Drawer.Trigger>
      <Drawer.Content className="w-full pb-6">
        <Drawer.Header>
          <Drawer.Title>Selecione o sabor</Drawer.Title>
        </Drawer.Header>
        <Separator />

        {flavorsList
          .sort((a, b) => a.id - b.id)
          .map((flavor) => (
            <div
              key={flavor.id}
              className={`relative flex h-[52px] w-full items-center border-0 border-b border-solid border-gray-300 py-2 ${flavor?.name || (flavor?.flavor === value && 'bg-secondary-100')}`}>
              <Drawer.Close className="sr-only" ref={closeRef} />
              <Button
                unstyled
                full
                className="relative flex items-start justify-between px-4"
                onClick={() => {
                  onChange(flavor.id)
                  closeRef.current?.click()
                }}>
                <div className="text-left">
                  <Typography.Text muted weight="semibold" type="paragraphTwo">
                    {flavor?.name || flavor?.flavor}
                  </Typography.Text>
                </div>

                {flavor?.isRecommendedFlavor && (
                  <div className="flex min-w-fit items-center gap-2">
                    <MdStars size={20} className="text-primary" />
                    <Typography.Text type="captionOne" muted className="text-primary-700">
                      {RecommendedText}
                    </Typography.Text>
                  </div>
                )}
              </Button>
            </div>
          ))}
      </Drawer.Content>
    </Drawer.Root>
  )
}

export {
  Root,
  HeaderImage,
  Header,
  HeaderInfo,
  HeaderBadges,
  Footer,
  Price,
  RemoveButton,
  FreeShippingProgress,
  FlavorSelect
}
