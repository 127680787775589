import styled from 'styled-components'

export const Wrapper = styled.main`
  min-height: 700px;
  width: 100%;
  padding: 0 8px;
`

export const Heading = styled.h1`
  padding: 16px 0;
  margin-bottom: 22px;
`

export const ContentUpload = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`

export const ButtonSpace = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 24px;
  margin-top: 10px;
  margin-bottom: 10px
`