import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Modal, ScrollArea } from '@cannect/new-components/atoms'
import { TModalActions } from '@cannect/new-components/atoms/Modal'
import { TModalPreviewFileMethods, TModalPreviewFileProps } from './types'
import { Document, Page, pdfjs } from 'react-pdf'
import useMediaQuery from '@cannect/hooks/useMediaQuery'
import { ImSpinner2 } from 'react-icons/im'
import { ScrollBar } from '@cannect/new-components/atoms/ScrollArea'

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString()

export const ModalPreviewFile = forwardRef<TModalPreviewFileMethods, TModalPreviewFileProps>(
  ({ file_url, fileType }, ref) => {
    const refModal = useRef<TModalActions>(null)

    const onShow = () => {
      refModal.current?.open()
    }

    const onClose = () => refModal.current?.close()

    useImperativeHandle(ref, () => ({
      onShow,
      onClose
    }))

    const isMobile = useMediaQuery('(max-width: 600px)')
    const [pdfNumPages, setPdfNumPages] = useState(0)

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
      setPdfNumPages(numPages)
    }

    return (
      <Modal ref={refModal} data-testid="modal_preview_file">
        <ScrollArea data-testid="preview_pdf" className="m-3 h-[60dvh] min-w-[300px] max-w-full rounded">
          {fileType === '.pdf' ? (
            <Document
              file={file_url}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<ImSpinner2 size={20} className="animate-spin text-primary-700" />}>
              {Array.from(new Array(pdfNumPages), (el, index) => (
                <Page
                  width={isMobile ? 400 : 800}
                  scale={1.3}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderMode="canvas"
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  loading={<ImSpinner2 size={20} className="animate-spin text-primary-700" />}
                />
              ))}
            </Document>
          ) : (
            <img src={file_url} className="max-w-[800px] object-cover" alt="Preview do documento" />
          )}
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </Modal>
    )
  }
)
