import { useHistory, useLocation } from 'react-router'
import { TDigitalJourneyFormData } from '../../types'
import { useUpdateCartMutation } from '@cannect/services/resources/digitalJourney'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCartActions } from '../../../../hooks/useCartActions'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'
import { toast } from 'react-toastify'
import { getEstimatedArrivalDates } from '@cannect/utils/date'
import { FORM_DEFAULT_VALUES } from '../../constants'

export const useCartStep = () => {
  const history = useHistory()
  const location = useLocation<{
    from?: string
  }>()
  const updateCartMutation = useUpdateCartMutation()

  const [loadingProducts, setLoadingProducts] = useState<{ [key: number]: boolean }>({})
  const [applyCouponLoading, setApplyCouponLoading] = useState(false)

  const { formData, updateFormData } = useDigitalJourney()

  const { productsFields, summaryFields, couponName, productsQuantity, isEmptyCart } = useMemo(
    () => ({
      productsFields: formData.cart?.products || [],
      summaryFields: formData.cart?.summary,
      couponName: formData.cart?.coupon_name,
      productsQuantity: (formData.cart?.products || []).reduce((acc, curr) => acc + curr.product_quantity, 0) || 0,
      isEmptyCart: (formData.cart?.products || []).length === 0
    }),
    [formData?.cart]
  )

  const [couponValue, setCouponValue] = useState(couponName || '')

  const handleUpdateCart = useCallback(
    (updatedData: TDigitalJourneyFormData['cart'], productId?: number) => {
      if (formData.common_fields?.digital_journey_id) {
        if (productId) setLoadingProducts((prev) => ({ ...prev, [productId]: true }))

        return updateCartMutation.mutateAsync(
          {
            digital_journey_id: formData.common_fields.digital_journey_id,
            coupon_name: updatedData?.coupon_name || null,
            products: updatedData?.products?.map((product) => ({
              product_id: product.product_id,
              product_quantity: product.product_quantity
            }))
          },
          {
            onSuccess: (data) => {
              updateFormData({
                cart: {
                  products: data?.form_data?.cart?.products || [],
                  summary: data?.form_data?.cart?.summary ?? formData?.cart.summary,
                  coupon_name: data?.form_data?.cart?.coupon_name,
                  next_step: data?.form_data?.cart?.next_step
                }
              })
            },
            onSettled: () => {
              if (productId) setLoadingProducts((prev) => ({ ...prev, [productId]: false }))
            }
          }
        )
      }
      return null
    },
    [formData.common_fields?.digital_journey_id, productsFields]
  )

  const { addProduct, removeProduct, changeProductQuantity, changeProductFlavor } = useCartActions(
    productsFields.map((product) => ({
      product_id: product.product_id,
      product_quantity: product.product_quantity
    }))
  )

  const handleAddProduct = useCallback(
    (productId: number) => {
      const newProduct = { product_id: productId, product_quantity: 1 }
      const updatedProducts = addProduct(newProduct)
      handleUpdateCart(
        {
          ...formData.cart,
          products: updatedProducts
        },
        productId
      )?.then(() => {
        toast.success('Produto adicionado ao carrinho.')
      })
    },
    [addProduct, formData.cart, handleUpdateCart]
  )

  const handleRemoveProduct = useCallback(
    (productId: number) => {
      const updatedProducts = removeProduct(productId)
      if (updatedProducts?.length > 0) {
        handleUpdateCart(
          {
            ...formData.cart,
            products: updatedProducts
          },
          productId
        )?.then(() => {
          toast.success('Produto removido do carrinho.')
        })
      } else {
        updateFormData({
          cart: {
            ...formData.cart,
            coupon_name: null,
            summary: FORM_DEFAULT_VALUES.cart.summary,
            products: []
          }
        })
      }
    },
    [removeProduct, formData.cart, handleUpdateCart]
  )

  const handleChangeProductQuantity = useCallback(
    (productId: number, quantity: number) => {
      const updatedProducts = changeProductQuantity(productId, quantity)
      handleUpdateCart(
        {
          ...formData.cart,
          products: updatedProducts
        },
        productId
      )
    },
    [changeProductQuantity, formData.cart, handleUpdateCart]
  )

  const handleChangeProductFlavor = useCallback(
    (oldProductId: number, newProductId: number) => {
      const updatedProducts = changeProductFlavor(oldProductId, newProductId)
      handleUpdateCart(
        {
          ...formData.cart,
          products: updatedProducts
        },
        newProductId
      )
    },
    [changeProductFlavor, formData.cart, handleUpdateCart]
  )

  const handleApplyCoupon = useCallback(
    (couponCode: string) => {
      setApplyCouponLoading(true)
      handleUpdateCart({
        ...formData.cart,
        coupon_name: couponCode
      })
        ?.then((data) => {
          if (data?.form_data?.cart?.coupon_name) {
            toast.success('Cupom aplicado com sucesso.')
          } else {
            toast.error('Cupom inválido.')
          }
        })
        .catch(() => {
          toast.error('Cupom inválido.')
        })
        .finally(() => {
          setApplyCouponLoading(false)
        })
    },
    [formData.cart, handleUpdateCart]
  )

  const estimatedArrivalDays = useMemo(() => getEstimatedArrivalDates(25, 30), [])

  const handleNextStep = () => {
    handleUpdateCart({
      ...formData.cart
    })?.then((data) => {
      if (location.state?.from) {
        history.push(location.state.from)
        return
      }
      if (data?.next_step) {
        history.push(data.next_step)
      }
    })
  }

  return {
    handleUpdateCart,
    productsFields,
    summaryFields,
    couponName,
    productsQuantity,
    isEmptyCart,
    handleAddProduct,
    handleRemoveProduct,
    handleChangeProductQuantity,
    handleChangeProductFlavor,
    handleApplyCoupon,
    isUpdatingCart: updateCartMutation.isPending,

    loadingProducts,
    applyCouponLoading,
    history,
    estimatedArrivalDays,
    couponValue,
    setCouponValue,
    handleNextStep
  }
}
