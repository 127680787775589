import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import * as serializers from './serializers'

import * as requests from './requests'
import { queryClient } from '@cannect/query-client'
import { calculateNextPageParam, serializeOptions } from '@cannect/services/common'
import { TFlavor } from './types'

export const KEY_GET_PRODUCT_DETAIL = 'getProductDetail'
export const KEY_GET_PRODUCTS_FILTERS = 'getProductsFilters'
const KEY_GET_PRODUCTS_LIST = 'getProductsList'

export const useGetProductDetail = (productId: number, enabled?: boolean) =>
  useQuery({
    queryKey: [KEY_GET_PRODUCT_DETAIL, productId],
    queryFn: () => requests.getProductDetail(productId),
    select: (data) => ({
      ...data,
      product: {
        ...data.product,
        // find valid promotion and add a valid_promotion property
        ...serializers.findValidPromotion(data.product),
        // find recommended flavor and add a isRecomendedFlavor property
        flavors: data.product?.flavors?.map((flavor: TFlavor) => ({
          ...flavor,
          isRecommendedFlavor:
            serializers.findRecommendedFlavor(data.product?.flavors, data.product?.flavor) === flavor?.flavor
        }))
      }
    }),
    meta: {
      errorMessage: 'Erro ao recuperar os dados do produto.'
    },
    enabled: (!!productId && enabled) ?? true,
    staleTime: 180000 //
  })

export const prefetchFlavorsDetails = (flavorId: number) =>
  queryClient.prefetchQuery({
    queryKey: [KEY_GET_PRODUCT_DETAIL, flavorId],
    queryFn: () => requests.getProductDetail(flavorId),
    staleTime: 180000
  })

export const useGetProductsFilters = () =>
  useQuery({
    queryKey: [KEY_GET_PRODUCTS_FILTERS],
    queryFn: () => requests.getProductsFilters(),
    select: (data) => ({
      brand_id: serializeOptions(data.filters.brand_id),
      shape_id: serializeOptions(data.filters.shape_id),
      class_id: serializeOptions(data.filters.class_id),
      cannabidiol_id: serializeOptions(data.filters.cannabidiol_id),
      cbd_concentration: serializeOptions(data.filters.cbd_concentration),
      thc_concentration: serializeOptions(data.filters.thc_concentration),
      category_id: serializeOptions(data.filters.category_id)
    }),
    meta: {
      errorMessage: 'Erro ao carregar os filtros.'
    },
    staleTime: 180000
  })

export const useGetPublicProductsListInfiniteQuery = (queryParams: URLSearchParams, enabled?: boolean) => {
  return useInfiniteQuery({
    queryKey: [KEY_GET_PRODUCTS_LIST, queryParams.toString(), enabled],
    queryFn: ({ pageParam = 1 }) => {
      return requests.getPublicProductsList(pageParam.toString(), queryParams)
    },
    select: (data) => ({
      ...data,
      pages: data.pages.map((page) => ({
        ...page,
        products: {
          ...page.products,
          rows: page.products.rows.map((product) => ({
            ...product,
            ...serializers.findValidPromotion(product),
            flavors: product?.flavors?.map((flavor: TFlavor) => ({
              ...flavor,
              isRecommendedFlavor:
                serializers.findRecommendedFlavor(product?.flavors, product?.flavor) === flavor?.flavor
            }))
          }))
        }
      }))
    }),
    getNextPageParam: (lastPage, allPages) => {
      const itemsPerPage = Number(queryParams.get('limit') || 12)
      return calculateNextPageParam({
        currentPage: allPages.length,
        totalItems: lastPage.products.count,
        itemsPerPage
      })
    },
    meta: {
      errorMessage: 'Houve um erro ao recuperar a listagem de produtos.'
    },
    staleTime: 1000 * 60 * 2,
    enabled: enabled ?? true,
    initialPageParam: 1
  })
}
