import animation from '@cannect/constants/animation'
import { Button, Typography } from '@cannect/new-components/atoms'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { LuLoader2, LuMinus, LuPlus } from 'react-icons/lu'

export const QuantityCounter = ({
  quantity,
  onChangeQuantity,
  isLoading = false
}: {
  quantity: number
  onChangeQuantity: (newQuantity: number) => void
  isLoading?: boolean
}) => {
  const prevQuantityRef = useRef(quantity || 0)
  const animationDirection = quantity > prevQuantityRef.current ? 'increment' : 'decrement'

  useEffect(() => {
    prevQuantityRef.current = quantity
  }, [quantity])

  return (
    <div className="flex h-12 items-center gap-4 rounded-md border border-solid border-gray-300">
      <Button
        unstyled
        disabled={isLoading || quantity === 1}
        className="h-full rounded-none border-r border-gray-300 px-2"
        onClick={() => {
          onChangeQuantity(Math.max(0, quantity - 1))
        }}>
        <LuMinus />
      </Button>

      <AnimatePresence mode="popLayout">
        <div className="flex flex-col items-center">
          <motion.div
            key={quantity}
            initial={animationDirection === 'increment' ? animation.counter.slideUp : animation.counter.slideDown}
            animate={{ y: '0', opacity: 1, scale: 1, filter: 'blur(0px)' }}
            exit={animationDirection === 'increment' ? animation.counter.slideDown : animation.counter.slideUp}
            transition={animation.counter.spring}>
            <Typography.Text weight="medium" muted>
              {isLoading ? <LuLoader2 size={14} className="animate-spin" /> : quantity}
            </Typography.Text>
          </motion.div>
        </div>
      </AnimatePresence>

      <Button
        disabled={isLoading}
        unstyled
        className="h-full rounded-none border-l border-gray-300 px-2"
        onClick={() => {
          onChangeQuantity(quantity + 1)
        }}>
        <LuPlus />
      </Button>
    </div>
  )
}
