import { Button, FormController, Typography } from '@cannect/new-components/atoms'
import { InputField, SwitchField } from '@cannect/new-components/molecules'
import { useForm, useFormContext } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useHistory } from 'react-router'
import { DIGITAL_JOURNEY_ROUTES, FORM_DEFAULT_VALUES, FORM_SCHEMAS, STEPS_ENUM } from '../constants'
import { TDigitalJourneyFormData } from '../types'
import { useAuthInformationMutation } from '@cannect/services/resources/digitalJourney'
import { useEffect } from 'react'
import { useDigitalJourney } from '@cannect/hooks/useDigitalJourney'

export type TFormFields = TDigitalJourneyFormData['auth_informations']

export const AuthStep = () => {
  const history = useHistory()
  const initialInformationsForm = useForm<TFormFields>({
    mode: 'onBlur',
    defaultValues: FORM_DEFAULT_VALUES.auth_informations,
    resolver: zodResolver(FORM_SCHEMAS.auth_informations)
  })
  const { formData, updateFormData } = useDigitalJourney()

  const {
    formState: { isValid, isSubmitting, errors },
    control,
    handleSubmit,
    watch,
    getFieldState
  } = initialInformationsForm

  const { mutateAsync } = useAuthInformationMutation()

  useEffect(() => {
    if (!formData?.common_fields?.digital_journey_id) {
      history.push(DIGITAL_JOURNEY_ROUTES.send_prescription_document)
    }
  }, [formData?.common_fields?.digital_journey_id])

  const onSubmit = async (data: TFormFields) => {
    if (!formData?.common_fields?.digital_journey_id) return

    // eslint-disable-next-line consistent-return
    return mutateAsync(
      {
        digital_journey_id: formData.common_fields.digital_journey_id,
        ...data
      },
      {
        onSuccess: (response) => {
          updateFormData({
            auth_informations: {
              ...data
            },
            common_fields: {
              order_id: response?.order_id,
              user_id: response?.user_id
            }
          })

          return response?.next_step && history.push(response.next_step)
        },
        onError: (data) => {
          console.log('error', data)
        }
      }
    )
  }

  useEffect(() => {
    console.log('watch SendPrescriptionStep', watch())
  }, [watch()])

  useEffect(() => {
    console.log('errors', errors)
  }, [errors])
  return (
    <div className="flex flex-col">
      <div className="mb-2">
        <Typography.Heading className="text-center text-primary-700" type="headingSix">
          Obrigado! Agora precisamos fazer seu cadastro.
        </Typography.Heading>
      </div>

      <FormController.Form {...initialInformationsForm}>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
          <InputField
            variant="filledLight"
            name="full_name"
            label="Nome completo"
            placeholder="Insira o nome completo"
            control={control}
          />
          <InputField
            variant="filledLight"
            name="cpf"
            label="CPF"
            placeholder="000.000.000-00"
            mask="cpf"
            control={control}
          />
          <InputField
            variant="filledLight"
            name="email"
            label="E-mail"
            placeholder="Insira o e-mail"
            control={control}
          />
          <InputField
            variant="filledLight"
            name="phone"
            mask="phone"
            label="Celular"
            placeholder="(00) 00000-0000"
            control={control}
          />

          <SwitchField
            name="terms_communication_accepted"
            control={control}
            label="Eu concordo em receber comunicações."
          />

          <Button className="mb-1 mt-3" full type="submit" isLoading={isSubmitting} size="lg">
            Continuar
          </Button>
          <Typography.Text type="captionOne" className="text-center">
            Já possui cadastro? Entre e aproveite seus documentos e dados já validados:
          </Typography.Text>
          <Button full unstyled className="font-medium text-primary-700" size="lg">
            Já tenho cadastro
          </Button>
        </form>
      </FormController.Form>
    </div>
  )
}
